<template>
  <el-card>
    <common-user-center-title :title="'我的助力'"></common-user-center-title>
    <div class="boost-center-list">
      <div class="boost-center-item" v-for="item in myBoostList" :key="item.id"
            @click="toLaunchDetail(item)">
        <div>
          <img style="width: 50px;height: 50px" v-lazy="item.img.img"/>
        </div>
        <div>{{item.productName}}</div>
        <div>价格：{{item.price}}</div>
        <div>助力可减：{{item.boostPrice}}</div>
        <div>结束时间：{{item.usedEndTime}}</div>
      </div>
    </div>
    <!-- 分页区域 -->
    <el-pagination
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-card>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { myBoostListUrl } from '@/api/index'
export default {
  name: 'MyBoost',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      queryInfo: {
        status: '',
        page: 1,
        pageSize: 10
      },
      total: 0,
      myBoostList: [],
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getMyBoostList()
    },
    async getMyBoostList(){
      const{data:res} = await this.$http.get(myBoostListUrl,{params:this.queryInfo})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.myBoostList = res.data.list
      this.total = res.data.total
    },
    toLaunchDetail(boostInfo){
      console.log(boostInfo)
      this.$router.push({ path: '/boostLaunchDetail', query: { boostId: boostInfo.id } })
    },
    // 分页页码显示条数
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getMyBoostList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getMyBoostList()
    },
  }
}
</script>

<style scoped>

</style>
